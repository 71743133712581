.ToDo {
  padding: 30px;
  background-color: rgb(230, 245, 230);
}

.button {
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: right;
  border: 0px none;
}

.todoevent {
  background-color: rgb(205, 245, 205);
  border-bottom: solid 1px rgb(255, 255, 255);
}

.noborder {
  border: 0px none;
}

.btn {
  margin-right: 5px;
}

.br {
  margin-bottom: 50px;
}

.footer {
  margin-top: 50px;
}